import React from 'react';

const Analytics = () => {
    return (
        <div>
            <h2>Analytics</h2>
            <p>This is the Analytics page where you can view various analytics data.</p>
        </div>
    );
};

export default Analytics;
