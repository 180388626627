import React, { useState, useEffect } from 'react';

const PurchaseStock = () => {
  const [stockItems, setStockItems] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [editIndex, setEditIndex] = useState(null);
  const [formData, setFormData] = useState({
    id: '', // Add an ID field for updates
    customerName: '',
    customerAddress: '',
    customerGstNumber: '',
    customerPhoneNumber: '',
    invoiceNumber: '',
    date: '',
    items: [],
    modeOfPayment: '',
    applyTax: false,
    cgst: '',
    sgst: '',
    cess: '',
    hsn_code: ''
  });

  const fetchStockItems = async () => {
    const response = await fetch('https://sub.charotarkisan.com/backend/purchase_invoice.php');
    const data = await response.json();
    console.log(data);
    setStockItems(data);
  };

  useEffect(() => {
    fetchStockItems();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleEdit = (index) => {
    setEditIndex(index);
    const item = stockItems[index];
    setFormData({
      id: item.id, // Set the ID for updates
      customerName: item.customer_name,
      customerAddress: item.customer_address,
      customerGstNumber: item.customer_gst_number,
      customerPhoneNumber: item.customer_phone_number,
      invoiceNumber: item.invoice_number,
      date: item.date,
      items: JSON.parse(item.items),
      modeOfPayment: item.mode_of_payment,
      applyTax: item.apply_tax === "1",
      cgst: item.cgst,
      sgst: item.sgst,
      cess: item.cess,
      hsn_code: item.hsn_code,
    });
  };

  const handleSave = async (index) => {
    const response = await fetch('https://sub.charotarkisan.com/backend/purchase_invoice.php', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ ...formData }), // Remove index and use ID for updating
    });

    if (response.ok) {
      const updatedItems = [...stockItems];
      updatedItems[index] = { ...updatedItems[index], ...formData }; // Update the item
      setStockItems(updatedItems);
      setEditIndex(null);
      setFormData({
        id: '',
        customerName: '',
        customerAddress: '',
        customerGstNumber: '',
        customerPhoneNumber: '',
        invoiceNumber: '',
        date: '',
        items: [],
        modeOfPayment: '',
        applyTax: false,
        cgst: '',
        sgst: '',
        cess: '',
        hsn_code: ''
      });
    }
  };

  const handleDelete = async (index) => {
    const itemToDelete = stockItems[index];
    const response = await fetch('https://sub.charotarkisan.com/backend/purchase_invoice.php', {
      method: 'DELETE', // Change to DELETE for clarity
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id: itemToDelete.id }), // Send ID for deletion
    });

    if (response.ok) {
      setStockItems(stockItems.filter((_, i) => i !== index));
    }
  };

  const handlePrint = () => {
    const win = window.open('', '', 'height=600,width=800');
    win.document.write(`
      <html>
        <head>
          <title>Purchase Stock</title>
          <style>
            body { font-family: Arial, sans-serif; margin: 20px; font-size: 14px; color: #333; line-height: 1.5; }
            h1 { text-align: center; }
            table { width: 100%; border-collapse: collapse; }
            th, td { border: 1px solid #000; padding: 8px; text-align: left; }
            th { background-color: #f2f2f2; }
          </style>
        </head>
        <body>
          <h1>Purchase Stock</h1>
          <table>
            <thead>
              <tr>
                <th>Customer Name</th>
                <th>Customer Address</th>
                <th>Customer GST Number</th>
                <th>Customer Phone Number</th>
                <th>Invoice Number</th>
                <th>Date</th>
                <th>Mode of Payment</th>
                <th>CGST</th>
                <th>SGST</th>
                <th>CESS</th>
                <th>HSN/SAC</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              ${stockItems.map(item => `
                <tr>
                  <td>${item.customer_name}</td>
                  <td>${item.customer_address}</td>
                  <td>${item.customer_gst_number}</td>
                  <td>${item.customer_phone_number}</td>
                  <td>${item.invoice_number}</td>
                  <td>${item.date}</td>
                  <td>${item.mode_of_payment}</td>
                  <td>${item.cgst}</td>
                  <td>${item.sgst}</td>
                  <td>${item.cess}</td>
                  <td>${item.hsn_code}</td>
                  <td>
                    <button onclick="editItem('${item.id}')">Edit</button>
                    <button onclick="deleteItem('${item.id}')">Delete</button>
                  </td>
                </tr>`).join('')}
            </tbody>
          </table>
        </body>
      </html>`);
    win.document.close();
    win.print();
  };

  const filteredItems = stockItems.filter(item =>
    item.customer_name && item.customer_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="container mt-5" style={{
      backgroundColor: "white",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      borderRadius: "8px",
      padding: "20px",
    }}>
      <h2 className="text-center">Purchase Stock</h2>
      <input
        type="text"
        placeholder="Search by Customer Name"
        value={searchQuery}
        onChange={e => setSearchQuery(e.target.value)}
        className="form-control mb-3"
      />
      <table className="table">
        <thead>
          <tr>
            <th>Customer Name</th>
            <th>Customer Address</th>
            <th>Customer GST Number</th>
            <th>Customer Phone Number</th>
            <th>Invoice Number</th>
            <th>Date</th>
            <th>Mode of Payment</th>
            <th>CGST</th>
            <th>SGST</th>
            <th>CESS</th>
            <th>HSN/SAC</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredItems.map((item, index) => (
            <tr key={index}>
              <td>
                {editIndex === index ? (
                  <input
                    type="text"
                    name="customerName"
                    value={formData.customerName}
                    onChange={handleChange}
                    className="form-control"
                  />
                ) : (
                  item.customer_name
                )}
              </td>
              <td>
                {editIndex === index ? (
                  <input
                    type="text"
                    name="customerAddress"
                    value={formData.customerAddress}
                    onChange={handleChange}
                    className="form-control"
                  />
                ) : (
                  item.customer_address
                )}
              </td>
              <td>
                {editIndex === index ? (
                  <input
                    type="text"
                    name="customerGstNumber"
                    value={formData.customerGstNumber}
                    onChange={handleChange}
                    className="form-control"
                  />
                ) : (
                  item.customer_gst_number
                )}
              </td>
              <td>
                {editIndex === index ? (
                  <input
                    type="text"
                    name="customerPhoneNumber"
                    value={formData.customerPhoneNumber}
                    onChange={handleChange}
                    className="form-control"
                  />
                ) : (
                  item.customer_phone_number
                )}
              </td>
              <td>
                {editIndex === index ? (
                  <input
                    type="text"
                    name="invoiceNumber"
                    value={formData.invoiceNumber}
                    onChange={handleChange}
                    className="form-control"
                  />
                ) : (
                  item.invoice_number
                )}
              </td>
              <td>
                {editIndex === index ? (
                  <input
                    type="date"
                    name="date"
                    value={formData.date}
                    onChange={handleChange}
                    className="form-control"
                  />
                ) : (
                  item.date
                )}
              </td>
              <td>
                {editIndex === index ? (
                  <input
                    type="text"
                    name="modeOfPayment"
                    value={formData.modeOfPayment}
                    onChange={handleChange}
                    className="form-control"
                  />
                ) : (
                  item.mode_of_payment
                )}
              </td>
              <td>
                {editIndex === index ? (
                  <input
                    type="text"
                    name="cgst"
                    value={formData.cgst}
                    onChange={handleChange}
                    className="form-control"
                  />
                ) : (
                  item.cgst
                )}
              </td>
              <td>
                {editIndex === index ? (
                  <input
                    type="text"
                    name="sgst"
                    value={formData.sgst}
                    onChange={handleChange}
                    className="form-control"
                  />
                ) : (
                  item.sgst
                )}
              </td>
              <td>
                {editIndex === index ? (
                  <input
                    type="text"
                    name="cess"
                    value={formData.cess}
                    onChange={handleChange}
                    className="form-control"
                  />
                ) : (
                  item.cess
                )}
              </td>
              <td>
                {editIndex === index ? (
                  <input
                    type="text"
                    name="hsn_code"
                    value={formData.hsn_code}
                    onChange={handleChange}
                    className="form-control"
                  />
                ) : (
                  item.hsn_code
                )}
              </td>
              <td>
                {editIndex === index ? (
                  <button onClick={() => handleSave(index)} className="btn btn-success">Save</button>
                ) : (
                  <>
                    <button onClick={() => handleEdit(index)} className="btn btn-warning">Edit</button>
                    <button onClick={() => handleDelete(index)} className="btn btn-danger">Delete</button>
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <button onClick={handlePrint} className="btn btn-info">Print Stock</button>
    </div>
  );
};

export default PurchaseStock;
