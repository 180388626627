import React, { useState } from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import './Dashboard.css';
import Card from '../card'; // Import the Card component

const Dashboard = () => {
    const [isOpen, setIsOpen] = useState(true);
    const navigate = useNavigate(); // Initialize the navigate function

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    const handleLogout = () => {
        // Add your logout logic here (e.g., clearing tokens, etc.)
        navigate('/'); // Redirect to the login page
    };

    return (
        <div className={`d-flex ${isOpen ? '' : 'toggled'}`} id="wrapper">
            {/* Sidebar */}
            <div className={`sidebar ${isOpen ? '' : 'd-none'}`} id="sidebar-wrapper">
                <div className="sidebar-heading text-center">Admin Dashboard</div>
                <div className="list-group list-group-flush">
                    <Link to="/dashboard" className="list-group-item list-group-item-action">
                        <i className="fas fa-tachometer-alt"></i> Dashboard
                    </Link>
                    <Link to="/dashboard/purchase" className="list-group-item list-group-item-action">
                        <i className="fas fa-user"></i> Purchase Invoice
                    </Link>
                    <Link to="/dashboard/stock" className="list-group-item list-group-item-action">
                        <i className="fas fa-box"></i> Stock
                    </Link>
                    <Link to="/dashboard/analytics" className="list-group-item list-group-item-action">
                        <i className="fas fa-chart-line"></i> Analytics
                    </Link>
                    <Link to="/dashboard/settings" className="list-group-item list-group-item-action">
                        <i className="fas fa-cogs"></i> Settings
                    </Link>
                </div>
                
                {/* Logout and App Version */}
                <div className="logout-container">
                    <button 
                        className="list-group-item list-group-item-action" 
                        onClick={handleLogout}
                    >
                        <i className="fas fa-sign-out-alt"></i> Logout
                    </button>
                    <div className="version-text text-center mt-3">
                        App Version: 1.0.0
                    </div>
                </div>
            </div>

            {/* Page Content */}
            <div id="page-content-wrapper">
                <nav className="navbar navbar-expand-lg navbar-light bg-light border-bottom">
                    <button className="btn btn-primary" onClick={toggleSidebar}>
                        <i className={`fas ${isOpen ? 'fa-angle-left' : 'fa-angle-right'}`}></i>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item active">
                                <Link className="nav-link" to="/dashboard">Dashboard</Link>
                            </li>
                        </ul>
                    </div>
                </nav>

                <div className="main-content">
                    <div className="card-container">
                        {/* Optional: Include Card components here */}
                    </div>

                    <Outlet />
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
