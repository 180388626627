import React from 'react';

const Settings = () => {
    return (
        <div>
            <h2>Settings</h2>
            <p>This is the Settings page where you can configure your application settings.</p>
        </div>
    );
};

export default Settings;
