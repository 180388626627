import React, { useState } from 'react';
import './login.css'; // Assuming you have your existing styles
import logo from '../assets/logo.jpeg';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false); // Loading state
  const [showPassword, setShowPassword] = useState(false); // State to manage password visibility

  const navigate = useNavigate(); // Initialize navigate

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading

    try {
      const response = await fetch('https://sub.charotarkisan.com/backend/login.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });

      if (response.ok) {
        const data = await response.json();
        setMessage(data.message);
        navigate('/dashboard'); // Navigate to the dashboard
      } else {
        const errorData = await response.json();
        setMessage(errorData.message || 'Login failed. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      setMessage('An error occurred while logging in. Please check your network connection.');
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <div className="container mt-5 mb-5">
      <div className="row justify-content-center">
        <div className="col-md-12">
          <div className="login-card shadow p-4">
            <img src={logo} alt="Logo" className="logo" style={{ width: "100px", borderRadius: "60px" }} />
            <h2 className="mt-3 text-center">Login</h2>
            <div className="slogan mt-4 text-center">
              <h5>Charotar Kisan</h5>
              <p>Your trusted agriproduct seller.</p>
              <p>Bringing the best of nature to your doorstep!</p>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="username">Username</label>
                <input
                  type="text"
                  className="form-control"
                  id="username"
                  placeholder="Enter username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="password">Password</label>
                <div className="input-group">
                  <input
                    type={showPassword ? 'text' : 'password'} // Toggle between text and password
                    className="form-control"
                    id="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  <div className="input-group-append">
                    <button
                      type="button"
                      className="btn btn-outline-secondary"
                      onClick={() => setShowPassword(!showPassword)} // Toggle visibility
                    >
                      <i className={`fas ${showPassword ? 'fa-eye-slash' : 'fa-eye'}`}></i>
                    </button>
                  </div>
                </div>
              </div>
              <div className="text-center">
                <button type="submit" className="btn btn-green btn-block" disabled={loading}>
                  {loading ? (
                    <>
                      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      Loading...
                    </>
                  ) : (
                    'Login'
                  )}
                </button>
              </div>
            </form>
            {message && <div className="alert alert-info mt-3">{message}</div>}

          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
