import React, { useState, useEffect } from 'react';
import { toWords } from 'number-to-words';

export default function PurchaseInvoice() {
  const [formData, setFormData] = useState({
    customerName: '',
    customerAddress: '',
    customerGstNumber: '',
    customerPhoneNumber: '',
    invoiceNumber: '',
    date: '',
    items: [],
    modeOfPayment: '',
    applyTax: false,
    cgst: '',
    sgst: '',
    cess: '', // Added CESS field
    hsn_code: '',
  });

  const [message, setMessage] = useState('');
  const [totals, setTotals] = useState({ totalAmount: 0, totalCGST: 0, totalSGST: 0, totalCESS: 0, totalTax: 0 });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleItemChange = (index, e) => {
    const { name, value } = e.target;
    setFormData((prevData) => {
      const newItems = [...prevData.items];
      newItems[index] = { ...newItems[index], [name]: value };
      return { ...prevData, items: newItems };
    });
  };

  const addItem = () => {
    setFormData((prevData) => ({
      ...prevData,
      items: [...prevData.items, { description: '', quantity: '', price: '', hsn_code: '' }],
    }));
  };

  const removeItem = (index) => {
    setFormData((prevData) => ({
      ...prevData,
      items: prevData.items.filter((_, i) => i !== index),
    }));
  };
  const calculateTotals = () => {
    let total = 0;
    let cgst = 0;
    let sgst = 0;
    let cess = 0;

    formData.items.forEach(item => {
        const quantity = parseFloat(item.quantity) || 0;
        const price = parseFloat(item.price) || 0;
        total += quantity * price;
    });

    if (formData.applyTax) {
        const cgstRate = parseFloat(formData.cgst) || 0;
        const sgstRate = parseFloat(formData.sgst) || 0;
        const cessRate = parseFloat(formData.cess) || 0;

        cgst = (total * cgstRate) / 100;
        sgst = (total * sgstRate) / 100;
        cess = (total * cessRate) / 100;
    }

    const totalIncludingTax = total + cgst + sgst + cess;

    setTotals({ 
        totalAmount: total, 
        totalCGST: cgst, 
        totalSGST: sgst, 
        totalCESS: cess, 
        totalTax: cgst + sgst + cess,
        totalIncludingTax // Add this line
    });
};

  useEffect(() => {
    calculateTotals();
  }, [formData]);

  const handlePrint = () => {
    const totalAmountInWords = toWords(totals.totalAmount);
    const win = window.open('', '', 'height=600,width=800');

    win.document.write(`
      <html>
        <head>
          <title>Tax Invoice</title>
          <style>
            body { font-family: Arial, sans-serif; margin: 20px; font-size: 14px; color: #333; line-height: 1.5; }
            h1 { text-align: center; font-size: 24px; margin-bottom: 20px; }
            h2 { font-size: 20px; margin-top: 20px; border-bottom: 2px solid #000; padding-bottom: 5px; }
            .section { margin-bottom: 20px; }
            .bold { font-weight: bold; }
            .align-right { text-align: right; }
            .footer { text-align: center; margin-top: 20px; }
            .logo { max-width: 150px; margin-bottom: 20px; }
            table { width: 100%; border-collapse: collapse; margin-top: 10px; }
            th, td { border: 1px solid #000; padding: 8px; text-align: left; }
            th { background-color: #f2f2f2; }
          </style>
        </head>
        <body>
          <div class="header">
            <img src="your-logo-url.png" alt="Company Logo" class="logo"/>
            <h1>Tax Invoice</h1>
          </div>
          <div class="section">
            <strong>Your Company Name</strong><br>
            Your Company Address<br>
            GST Number: Your GST Number<br>
            Phone Number: Your Phone Number<br>
            <div class="align-right">
              <strong>Invoice No:</strong> ${formData.invoiceNumber}<br>
              <strong>Dated:</strong> ${formData.date}
            </div>
          </div>
          <h2>Customer Details</h2>
          <div class="section">
            <strong>Customer Name:</strong> ${formData.customerName}<br>
            <strong>GSTIN/UIN:</strong> ${formData.customerGstNumber}<br>
            <strong>Address:</strong> ${formData.customerAddress}<br>
            <strong>Phone:</strong> ${formData.customerPhoneNumber}
          </div>
          <h2>Invoice Items</h2>
          <table>
            <thead>
              <tr>
                <th>Sl No.</th>
                <th>Description of Goods</th>
                <th>HSN/SAC</th>
                <th>Quantity</th>
                <th>Rate</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              ${formData.items.map((item, index) => `
                <tr>
                  <td>${index + 1}</td>
                  <td>${item.description}</td>
                  <td>${item.hsn_code}</td>
                  <td>${item.quantity}</td>
                  <td>₹${parseFloat(item.price).toFixed(2)}</td>
                  <td>₹${(parseFloat(item.quantity) * parseFloat(item.price)).toFixed(2)}</td>
                </tr>`).join('')}
            </tbody>
          </table>
          <h2>Total Amount</h2>
          <div class="section">
            <div class="align-right bold">Total: ₹${totals.totalAmount.toFixed(2)}</div>
            ${formData.applyTax ? `
              <div class="align-right">Total CGST: ₹${totals.totalCGST.toFixed(2)}</div>
              <div class="align-right">Total SGST: ₹${totals.totalSGST.toFixed(2)}</div>
              <div class="align-right">Total CESS: ₹${totals.totalCESS.toFixed(2)}</div>` : ''}
          </div>
          <p>Amount Chargeable (in words): <strong>Indian Rupee ${totalAmountInWords} Only</strong></p>
          ${formData.applyTax ? `
            <p>Tax Amount (in words): <strong>Indian Rupee ${toWords(totals.totalTax)} Only</strong></p>` : ''}
          <p style="margin-top: 20px;">Declaration:<br/> We declare that this invoice shows the actual price of the goods described and that all particulars are true and correct.</p>
          <div class="footer">
            For Your Company Name,<br/>
            Authorized Signatory
          </div>
        </body>
      </html>`);

    win.document.close();
    win.print();
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Submitting form data:', formData); // Log the form data

    // Basic validation for required fields
    if (!formData.customerName || !formData.customerAddress || 
        !formData.customerGstNumber || !formData.invoiceNumber || 
        !formData.date || formData.items.length === 0) {
        setMessage('Please fill out all required fields.');
        return;
    }

    // Validate each item in the items array
    for (let item of formData.items) {
        console.log('Item:', item); // Log each item
        if (!item.description || !item.quantity || !item.price) {
            setMessage('Please fill out all fields for each item.');
            return;
        }
    }

    // Ensure quantity and price are valid numbers
    const formattedItems = formData.items.map(item => ({
        ...item,
        quantity: parseFloat(item.quantity),
        price: parseFloat(item.price),
    }));

    try {
        const response = await fetch('https://sub.charotarkisan.com/backend/purchase_invoice.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                ...formData,
                items: formattedItems, // Send formatted items
            }),
        });

        const result = await response.json();
        if (result.success) {
            setMessage('Invoice created successfully!');
            setFormData({
                customerName: '',
                customerAddress: '',
                customerGstNumber: '',
                customerPhoneNumber: '',
                invoiceNumber: '',
                date: '',
                items: [],
                modeOfPayment: '',
                applyTax: false,
                cgst: '',
                sgst: '',
                cess: '',
                hsn_code: ''
            });
        } else {
            setMessage(result.error || 'Failed to create invoice.');
        }
    } catch (error) {
        setMessage('An error occurred: ' + error.message);
    }
};


  return (
    <div className="container mt-5" style={{backgroundColor:"white", borderRadius:"10px", padding:"20px"}}>
      <h2 className="text-center">Create Purchase Invoice</h2>
      {message && <div className="alert alert-info">{message}</div>}
      <form onSubmit={handleSubmit}>
        <div className="row mb-3">
          {['customerName', 'customerAddress', 'customerGstNumber', 'customerPhoneNumber', 'invoiceNumber', 'date'].map((field, index) => (
            <div className="col-md-4 form-group" key={index}>
              <label>{field.replace(/([A-Z])/g, ' $1').toUpperCase()}</label>
              <input
                type={field === 'date' ? 'date' : 'text'}
                name={field}
                value={formData[field]}
                onChange={handleChange}
                className="form-control"
                required={field !== 'customerPhoneNumber'}
                aria-label={field.replace(/([A-Z])/g, ' ')}
              />
            </div>
          ))}
          <div className="col-md-4 form-group">
            <label>Mode of Payment</label>
            <input
              type="text"
              name="modeOfPayment"
              value={formData.modeOfPayment}
              onChange={handleChange}
              className="form-control"
            />
          </div>
          <div className="col-md-4 form-group">
            <label>Apply Tax</label>
            <input
              type="checkbox"
              name="applyTax"
              checked={formData.applyTax}
              onChange={handleChange}
              className="form-check-input"
            />
          </div>
        </div>
        {formData.applyTax && (
          <div className="row mb-3">
            {['cgst', 'sgst', 'cess'].map((field, index) => (
              <div className="col-md-4 form-group" key={index}>
                <label>{field.toUpperCase()} (%)</label>
                <input
                  type="number"
                  name={field}
                  value={formData[field]}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
            ))}
          </div>
        )}
        <div className="row mb-3">
          <div className="col-md-12">
            <h4>Items</h4>
            {formData.items.map((item, index) => (
              <div key={index} className="row mb-2">
                {['description', 'quantity', 'price', 'hsn_code'].map((field, idx) => (
                  <div className={`col-md-${field === 'description' ? '4' : '2'} form-group`} key={idx}>
                    <label>{field.charAt(0).toUpperCase() + field.slice(1)}</label>
                    <input
                      type={field === 'quantity' || field === 'price' ? 'number' : 'text'}
                      name={field}
                      value={item[field]}
                      onChange={(e) => handleItemChange(index, e)}
                      className="form-control"
                      required={field !== 'hsn_code'}
                    />
                  </div>
                ))}
                <div className="col-md-2 form-group">
                  <button type="button" onClick={() => removeItem(index)} className="btn btn-danger">Remove</button>
                </div>
              </div>
            ))}
            <button type="button" onClick={addItem} className="btn btn-primary">Add Item</button>
          </div>
        </div>
        <div className="row mb-3">
    <div className="col-md-12">
        <h4>Total Summary</h4>
        <div className="align-right bold">Total (Excluding Tax): ₹{totals.totalAmount.toFixed(2)}</div>
        {formData.applyTax && (
            <>
                <div className="align-right">Total CGST: ₹{totals.totalCGST.toFixed(2)}</div>
                <div className="align-right">Total SGST: ₹{totals.totalSGST.toFixed(2)}</div>
                <div className="align-right">Total CESS: ₹{totals.totalCESS.toFixed(2)}</div>
            </>
        )}
        <div className="align-right bold">Total (Including Tax): ₹{totals.totalAmount + totals.totalCGST + totals.totalSGST + totals.totalCESS}</div>
    </div>
</div>
        <div className="d-flex justify-content-between">
          <button type="submit" className="btn btn-success">Create Invoice</button>
          <button type="button" onClick={handlePrint} className="btn btn-info">Print Invoice</button>
        </div>
      </form>
    </div>
  );
}
