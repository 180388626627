import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './components/login';
import Dashboard from './components/dashboard/Dashboard';
import Purchase from './components/Purchase/purchase'; // Import Purchase component
import PurchaseStock from './components/Purchase/PurchaseStock'; // Import PurchaseStock component
import Analytics from './components/Analysis/Analysis'; // Import Analytics component
import Settings from './components/Setting/Settings'; // Import Settings component
import { ToastContainer } from "react-toastify"; 

const App = () => {
    return (
        <Router>
            <ToastContainer /> {/* Add ToastContainer for notifications */}

            <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/dashboard" element={<Dashboard />}>
                    <Route path="purchase" element={<Purchase />} /> {/* Nested route for Purchase */}
                    <Route path="stock" element={<PurchaseStock />} /> {/* New route for PurchaseStock */}
                    <Route path="analytics" element={<Analytics />} /> {/* New route for Analytics */}
                    <Route path="settings" element={<Settings />} /> {/* New route for Settings */}
                </Route>
            </Routes>
        </Router>
    );
};

export default App;
